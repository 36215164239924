import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiOperationOutsourcesFindPostRequest, DefaultApiOperationOutsourcesGetRequest, DefaultApiOperationOutsourcesOperationOutsourceIdDeleteRequest, DefaultApiOperationOutsourcesOperationOutsourceIdGetRequest, DefaultApiOperationOutsourcesOperationOutsourceIdPutRequest, DefaultApiOperationOutsourcesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import DefaultInstance from "instance/axios";


export const operationOutsources = createQueryKeys('operationOutsources', {
    all: null,
    get: (params: DefaultApiOperationOutsourcesGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.operationOutsourcesGet(query)
        }
    },
    detail: (params: DefaultApiOperationOutsourcesOperationOutsourceIdGetRequest) => ({
        queryKey: [params.operationOutsourceId],
        queryFn: () => DefaultInstance.operationOutsourcesOperationOutsourceIdGet(params)
    }),
    find: (params: DefaultApiOperationOutsourcesFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.operationOutsourcesFindPost(query)
        }
    },
})

export const mutateOperationOutsources = createMutationKeys('operationOutsources', {
    create: (params: DefaultApiOperationOutsourcesPostRequest) => ({
        mutationKey: [params.operationOutsourcesGetRequest],
        mutationFn: () => DefaultInstance.operationOutsourcesPost(params),
    }),
    update: (params: DefaultApiOperationOutsourcesOperationOutsourceIdPutRequest) => ({
        mutationKey: [params.operationOutsourceId, params.operationOutsourcesGetRequest],
        mutationFn: () => DefaultInstance.operationOutsourcesOperationOutsourceIdPut(params)
    }),
    delete: (params: DefaultApiOperationOutsourcesOperationOutsourceIdDeleteRequest) => ({
        mutationKey: [params.operationOutsourceId],
        mutationFn: () => DefaultInstance.operationOutsourcesOperationOutsourceIdDelete(params)
    }),

});

export const operationOutsourcesQueryKeys = mergeQueryKeys(operationOutsources, mutateOperationOutsources);