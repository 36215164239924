interface CompanyConfig {
    name: string;
    messages: {
        activeWorkMessage: string;
        inputLogMessage: string;
        outOfStockMessage: string;
        correctMessage: string;
        flushDetailMessage: string;
        endMessage: string;
        cancelInputMessage: string;
    };
}

const companyConfigs: { [key: string]: CompanyConfig } = {
    'speedrack': {
        name: "speedrack",
        messages: {
            activeWorkMessage: "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?",
            inputLogMessage: "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
            outOfStockMessage: "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
            correctMessage: "이전 자재를 사용 완료 하였습니까?",
            flushDetailMessage: "재고가 남아 있습니다. 플러싱을 진행하시겠습니까?",
            endMessage: "작업을 종료하시겠습니까?",
            cancelInputMessage: '투입을 취소하시겠습니까?'

        }
    },
    'daou': {
        name: "daou",
        messages: {
            activeWorkMessage: "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?",
            inputLogMessage: "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
            outOfStockMessage: "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
            correctMessage: "이전 자재를 사용 완료 하였습니까?",
            flushDetailMessage: "재고가 남아 있습니다. 플러싱을 진행하시겠습니까?",
            endMessage: "작업을 종료하시겠습니까?",
            cancelInputMessage: '투입을 취소하시겠습니까?'
        }
    },
    'daeil': {
        name: "daeil",
        messages: {
            activeWorkMessage: "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?",
            inputLogMessage: "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
            outOfStockMessage: "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
            correctMessage: "이전 자재를 사용 완료 하였습니까?",
            flushDetailMessage: "재고가 남아 있습니다. 플러싱을 진행하시겠습니까?",
            endMessage: "작업을 종료하시겠습니까?",
            cancelInputMessage: '투입을 취소하시겠습니까?'
        }
    },
    'woojang': {
        name: "woojang",
        messages: {
            activeWorkMessage: "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?",
            inputLogMessage: "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
            outOfStockMessage: "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
            correctMessage: "이전 자재를 사용 완료 하였습니까?",
            flushDetailMessage: "재고가 남아 있습니다. 플러싱을 진행하시겠습니까?",
            endMessage: "작업을 종료하시겠습니까?",
            cancelInputMessage: '투입을 취소하시겠습니까?'
        }
    },
    'taewon': {
        name: "taewon",
        messages: {
            activeWorkMessage: "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?",
            inputLogMessage: "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
            outOfStockMessage: "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
            correctMessage: "이전 자재를 사용 완료 하였습니까?",
            flushDetailMessage: "재고가 남아 있습니다. 플러싱을 진행하시겠습니까?",
            endMessage: "작업을 종료하시겠습니까?",
            cancelInputMessage: '투입을 취소하시겠습니까?'
        }
    },
    'gana': {
        name: "gana",
        messages: {
            activeWorkMessage: "이미 작업중인 작업 지시서가 있습니다. 이전의 작업을 종료하시겠습니까?",
            inputLogMessage: "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
            outOfStockMessage: "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
            correctMessage: "이전 자재를 사용 완료 하였습니까?",
            flushDetailMessage: "재고가 남아 있습니다. 플러싱을 진행하시겠습니까?",
            endMessage: "작업을 종료하시겠습니까?",
            cancelInputMessage: '투입을 취소하시겠습니까?'
        }
    }
};

export default companyConfigs;
