import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { DowntimeReasonsAutoComplete } from "components/common/autoComplete/downtimeReasons/downtimeReasons-autoComplete";
import { BaseButton } from "components/common/button/baseButton";
import { BaseTitle } from "components/common/title/BaseTitle";
import { useState } from "react";
import { theme } from "styles/theme";
import { FormMain } from "../FormMain";
const Pause = Object.assign(FormMain, {
  PauseTitle: BaseTitle,
  PauseButton: BaseButton,
});

interface PauseFormProps {
  onClick: (downtimeReasonCode: string) => void;
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined;
}

export const DownTimeReasonsForm = (params: PauseFormProps) => {

  const { onClick, workData } = params;
  const [downtimeReasonCode, setDowntimeReasonCode] = useState<string>("");

  const handleClick = () => {
    onClick(downtimeReasonCode);
  }


  return (
    <Pause w={"60rem"} h={"18rem"} m={"2rem"}>
      <Pause.PauseTitle size={"3rem"} mb={"lg"} c={"#000000"} weight={700} lh={"130%"}>
        {"비가동 사유 선택"}
      </Pause.PauseTitle>
      <Flex align={"center"} gap={8}>
        <DowntimeReasonsAutoComplete
          styles={{
            input: {
              height: "5rem",
              width: "45rem",
            },
          }}
          value={downtimeReasonCode}
          maxDropdownHeight={400}
          rightSectionWidth={60}
          width="35rem"
          size="2rem"
          routingCode={workData?.operationData?.routingCode}
          onChange={(e) => setDowntimeReasonCode(e ?? "")}
        />
        <Pause.PauseButton
          disabled={!downtimeReasonCode}
          h="5rem"
          rightIcon={<IconDeviceFloppy />}
          onClick={handleClick}
          bg={theme?.colors?.orange?.[6]}
        >
          {"저장"}
        </Pause.PauseButton>
      </Flex>
    </Pause>
  );
};
