import { equipments } from "api/equipmentCount/useEquipmentQuery";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSocket, useSocketEvent } from "socket.io-react-hook";
import { UseGetEquipmentCounterProps } from "./types";

export const useGetEquipmentCounter = ({
  equipmentCode,
  equipmentId,
  enabled,
}: UseGetEquipmentCounterProps): {
  equipmentCounter: string;
  equipmentLimitCounter: string;
  isLimitHit: boolean;
} => {

  const { setTempWork, tempWork } = useLocalStorage();

  const { socket } = useSocket(process.env.REACT_APP_BASE_URL as string, {
    autoConnect: true,
    enabled: enabled,
  });

  if (!equipmentCode && !equipmentId && !enabled) {
    socket?.disconnect();
  }

  const { refetch } = useQuery({
    ...equipments.detail({
      equipmentId: equipmentId
    }), enabled: !!equipmentId,
    onSuccess: (res) => setTempWork({
      end: res?.data?.equipmentCounter
    })
  });

  const roomName = equipmentCode ? `EC_${equipmentCode}` : "";

  if (roomName && socket?.connected) {
    socket.emit("call", "socket.join", { room: roomName });
  }

  const { lastMessage } = useSocketEvent(socket, roomName);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document?.hidden) {
        console.log('페이지가 비활성화되었습니다.');
      } else {
        console.log('페이지가 활성화되었습니다.');
        if (enabled) {
          refetch();
        }
      }
    };

    document?.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document?.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [socket, refetch]);

  useEffect(() => {
    if (lastMessage) {
      setTempWork({
        ...tempWork,
        end: lastMessage?.equipmentCounter ?? tempWork?.end,
      });
    }
  }, [lastMessage]);

  // 소켓이 제대로 정의되지 않았을 때를 위한 방어적 코드
  if (!socket) {
    console.error('소켓이 정의되지 않았습니다.');
    return {
      equipmentCounter: '0',
      equipmentLimitCounter: '0',
      isLimitHit: false,
    };
  }

  return {
    equipmentCounter: tempWork?.end ?? '0',
    equipmentLimitCounter: lastMessage?.equipmentLimitCounter ?? '0',
    isLimitHit: lastMessage?.isLimitHit ?? false,
  };
};
