import { Confirm } from "components/common/confirm";
import { DownTimeReasonsForm } from "components/common/form/downtimeReasons/DownTimeReasonsForm";
import { InputLogsForm } from "components/common/form/inputLogs/inputLogsForm";
import { LogsForm } from "components/common/form/logs/logsForm";
import { RawMaterialInputForm } from "components/common/form/material/RawMaterialForm";
import { InspectForm } from "components/common/form/work/InspectForm";
import { PalletBarcodeBox } from "components/pages/work/workPalletSeparate/palletBarcodeBox";
import companyConfigs from "config/companyConfigs";
import { ModalCallbacks, handleFlushType, handleInputLogsType, handleInspectSpecificationType, handleLimitHitType, handlePauseType, handleRawMaterialType, handleSeparatePalletType, handleWorkLogsType } from "./types";

class CommonModalHandler {

    private modal: any; // Adjust type according to useModal implementation
    private companyKey: string;
    private messages: any; // Adjust type according to companyConfigs structure

    constructor(modal: any) {
        this.modal = modal
        this.companyKey = process.env.REACT_APP_COMPANY_KEY as string;
        this.messages = companyConfigs[this.companyKey]?.messages ?? '지정된 메세지가 존재 하지 않습니다.';
    }

    private openConfirmModal(message: string | JSX.Element, title: string, params: ModalCallbacks) {
        const { yesCallback, noCallback, commonCallback } = params;
        this.modal.openModal(
            <Confirm
                message={message}
                yesCallback={yesCallback}
                noCallback={noCallback}
                commonCallback={commonCallback}
            />,
            "",
            title
        );
    }

    public handleActiveWork(params: ModalCallbacks) {
        const { activeWorkMessage } = this.messages;
        this.openConfirmModal(activeWorkMessage, "작업 중인 지시서 확인", params);
    }

    public handleInputLog(params: ModalCallbacks) {
        const { inputLogMessage } = this.messages;
        this.openConfirmModal(inputLogMessage, "생산실적 입력 여부", params);
    }

    public handleOutOfStock(params: ModalCallbacks) {
        const { outOfStockMessage } = this.messages;
        this.openConfirmModal(outOfStockMessage, "재고 부족 여부 확인", params);
    }

    public handleCorrect(params: ModalCallbacks) {
        const { correctMessage } = this.messages;
        this.openConfirmModal(correctMessage, "재고 사용 여부 확인", params);
    }

    public handleEnd(params: ModalCallbacks) {
        const { endMessage } = this.messages;
        this.openConfirmModal(endMessage, "작업 종료 확인", params);
    }

    public handleCancelInput(params: ModalCallbacks) {
        const { cancelInputMessage } = this.messages;
        this.openConfirmModal(cancelInputMessage, "투입 취소", params);
    }

    public handleLimitHit(params: handleLimitHitType) {

        const { workData } = params
        const { equipmentLimitCounter, defectTotal } = params

        const limitHitMessage = <div>
            <div>리밋 카운터에 도달하였습니다. 실적을 저장하시겠습니까?</div>
            <div>생산수량 : {equipmentLimitCounter} ({workData?.item?.unitText})</div>
            {defectTotal ?
                <div>총 불량수량 : {defectTotal} ({workData?.item?.unitText})</div> : undefined}
        </div>

        this.openConfirmModal(limitHitMessage, "작업 제한 횟수 도달", params);

    }

    public handleFlush(params: handleFlushType) {

        const { workInventoriesData } = params

        const message = workInventoriesData?.rows?.map((row) => (
            `${row.itemName}(${row.itemCode}) 재고가 ${row.quantity} ${row.unitText} 남아 있습니다. 초기화 하시겠습니까?`
        )).join('\n');

        this.openConfirmModal(message as string, "플러싱 여부 확인", params);
    }

    public handlePause(params: handlePauseType) {
        this.modal.openModal(
            <DownTimeReasonsForm
                onClick={(equipmentCode: string) => {
                    params.onPause(equipmentCode);
                    this.modal.closeModal({});
                }}
                workData={params.workData}
            />,
            "",
            "작업 일시정지",
            true
        );
    }

    public handleInspectSpecification(params: handleInspectSpecificationType) {
        this.modal.openModal(
            <InspectForm workData={params.workData} lotId={params.lotData?.id} />,
            "",
            "검사 기록 보고"
        );
    }

    public handleSeparatePallet(params: handleSeparatePalletType) {
        this.modal.openModal(
            <PalletBarcodeBox workData={params.workData} lotData={params.lotData} />,
            null,
            "파레트 분리",
            true
        );
    }

    public handleRawMaterial(params: handleRawMaterialType) {
        this.modal.openModal(
            <RawMaterialInputForm workData={params.workData} onInputSuccess={params.yesCallback} />,
            "",
            "원부자재 투입"
        );
    }

    public handleWorkLogs(params: handleWorkLogsType) {
        this.modal.openModal(<LogsForm workId={params.workId?.toString() as string} />, '', '작업 로그 확인');
    }

    public handleInputLogs = (params: handleInputLogsType) => {

        this.modal.openModal(<InputLogsForm workData={params.workData} />, '', '투입 기록 확인');
    }

}

export default CommonModalHandler;
